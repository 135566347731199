import React, { Component, Suspense } from "react";
import { HashRouter, Route, Routes } from "react-router-dom";
import "./scss/style.scss";
import PrivateRoute from "./util/private-route.js";

const loading = (
    <div className="pt-3 text-center">
        <div className="sk-spinner sk-spinner-pulse"></div>
    </div>
);

// Containers
const DefaultLayout = React.lazy(() => import("./layout/DefaultLayout"));

// Pages
const Login = React.lazy(() => import("./views/pages/login-prosul/Login"));
const Signup = React.lazy(() => import("./views/pages/signup/Signup"));
const Onboard = React.lazy(() => import("./views/pages/onboard/Onboard"));
const Register = React.lazy(() => import("./views/pages/register/Register"));
const Page404 = React.lazy(() => import("./views/pages/page404/Page404"));
const Page500 = React.lazy(() => import("./views/pages/page500/Page500"));


import routes from './routes'


class App extends Component {
    constructor(props) {
        super(props);
        let loginData = JSON.parse(localStorage.getItem("login-data"));

        if (loginData == null) {
            loginData = {
                ok: false,
                name: "",
            };
        }

        this.state = {
            loggedIn: loginData.ok,
            userName: loginData.name,
        };

        this.updateLoggedState = this.updateLoggedState.bind(this);
    }

    updateLoggedState(prop) {
        let loggedIn = prop.data.ok;

        this.setState({
            loggedIn: loggedIn,
            bl_admin: prop.data.bl_admin,

        });

        if (loggedIn) {
            window.location = "/#/trocar";
        }
    }

    render() {
        return (
            <HashRouter>
                <Suspense fallback={loading}>
                    <Routes>
                        <Route exact path="/" element={<PrivateRoute />}>
                            <Route exact path="/" element={<DefaultLayout />}>
                                {routes.map((route, idx) => {
                                    return (
                                        route.element && (
                                            <Route
                                                key={idx}
                                                path={route.path}
                                                name={route.name}
                                                element={<route.element />}
                                            />
                                        )
                                    )
                                })}
                            </Route>
                            <Route exact path="/onboard" name="Bem-vindo" element={<Onboard />} />
                            <Route exact path="/404" name="Page 404" element={<Page404 />} />
                            <Route exact path="/500" name="Page 500" element={<Page500 />} />
                        </Route>
                        <Route exact path="/register" element={<Register />} />
                        <Route exact path="/login" element={<Login updateLoggedState={this.updateLoggedState} />} />
                        <Route exact path="/register" name="Register Page" element={<Register />} />
                        <Route exact path="/signup" name="Cadastre-se" element={<Signup />} />
                    </Routes>
                </Suspense>
            </HashRouter>
        );
    }
}

export default App;

import 'react-app-polyfill/stable'
import 'core-js'
import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { Provider } from 'react-redux'
import store from './store'
import axios from 'axios';

axios.defaults.baseURL = 'http://ec2-3-234-67-160.compute-1.amazonaws.com';
// axios.defaults.baseURL = 'http://localhost:8080';
// axios.defaults.baseURL = 'https://apiprosul.bmt.eng.br';

axios.interceptors.request.use(function (config) {
	let loginData = JSON.parse(localStorage.getItem('login-data'));
	let token = '';

	if (loginData != null) {
		token = loginData.token;
		config.headers.common.Authorization = `Bearer ${token}`;
        config.headers.common.UserId = loginData.login;
		config.headers.common.UserCode = loginData.user_id;
	}

	return config;
});

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById('root'),
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()

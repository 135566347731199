import React from "react";
import { Navigate, Outlet } from "react-router-dom";

// Utils
import { getToken } from "./auth";

const PrivateRoute = () => {
  const auth = getToken() !== null;

  return auth ? <Outlet /> : <Navigate to="/login" />;
};
export default PrivateRoute;

import React from 'react';

const Oportunidades = React.lazy(() => import('./views/Oportunidades/Oportunidades'));
const Scenes = React.lazy(() => import('./views/Scenes/Scenes'));
const Projects = React.lazy(() => import('./views/Projects/Projects'));
const Contrato = React.lazy(() => import('./views/contrato/Contrato'));
const Contratos = React.lazy(() => import('./views/contratos/Contratos'));
const Empreendimento = React.lazy(() => import('./views/empreendimento/Empreendimento'));
const Empreendimentos = React.lazy(() => import('./views/Empreendimentos/Empreendimentos'));
const Produto = React.lazy(() => import('./views/produto/Produto'));
const Subproduto = React.lazy(() => import('./views/subproduto/Subproduto'));
const Visualizador = React.lazy(() => import('./views/Visualizador/Visualizador'));
const Usuarios = React.lazy(() => import('./views/usuarios/Usuarios'));
const Permissoes = React.lazy(() => import('./views/Permissoes/Permissoes'));
const Licenciamento = React.lazy(() => import('./views/licenciamento/Licenciamento'));
const Bi = React.lazy(() => import('./views/bi/Bi'));


const routes = [
	{ path: '/oportunidades', name: 'Oportunidades', element: Oportunidades },
	{ path: '/my-scenes', name: 'Cenas', element: Scenes },
    { path: '/my-projects', name: 'Projetos', element: Projects },
    { path: '/contrato', name: 'Contratos', element: Contrato },
    { path: '/trocar', name: 'Contratos', element: Contratos },
    { path: '/empreendimento', name: 'Empreendimento', element: Empreendimento },
    { path: '/empreendimentos', name: 'Empreendimentos', element: Empreendimentos },
    { path: '/produto', name: 'Produto', element: Produto },
    { path: '/subproduto', name: 'Subproduto', element: Subproduto },
    { path: '/visualizador', name: 'Visualizador', element: Visualizador },
    { path: '/usuarios', name: 'Usuários', element: Usuarios },
    { path: '/permissoes', name: 'Permissões', element: Permissoes },
    { path: '/licenciamento', name: 'Licenciamento', element: Licenciamento },
    { path: '/bi', name: 'BI', element: Bi },
];

export default routes;
